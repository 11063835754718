:root {
  --dark-color: #112d4e;
  --active-color: #92ff9d;
  --white-color: #ffffff;
  --decorative-color: #ffd642;
  --border-extreme-light-color: #ffffff44;
  --border-medium-dark-color: #0000005d;
  --border-radius: 3px;
  --light-silver-shade-color: #eaeded;
  --green-bg-color: #04ff00;
  --dark-green-bg-color: #04da00;
  --pure-red-bg-color: #ff0000;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--light-silver-shade-color);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-color);
  /* background: red; */
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.leftSideQuestionsDetailsComponentMainDiv {
  border-radius: var(--border-radius);
  border: var(--border-medium-dark-color) 1px solid;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* all subjects line design. */
.subjectsNameMainDiv {
  height: 40px;
  background-color: var(--light-silver-shade-color);
  display: flex;
  /* justify-content: ; */
  /* align-items: center; */
  gap: 0;
  overflow-x: auto;
  overflow-y: hidden;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  /* border-right: var(--light-silver-shade-color) 2px solid; */
}
.subjectName,
.subjectNameFirst {
  /* width: max-content; */
  text-wrap: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
}
.subjectNameActive,
.subjectNameActiveFirst {
  /* height: 40px; */
  text-wrap: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
  /* scale: 1.1; */
  background-color: var(--decorative-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
}
/* .subjectNameFirst,
.subjectNameActiveFirst {
  border-top-left-radius: var(--border-radius);
} */

/* current question detail design. */
.currentQuestionDetailMainDiv {
  display: flex;
  /* justify-content: end; */
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  height: 40px;
  /* height: 35px; */
  border-bottom: var(--light-silver-shade-color) 2px solid;
  padding-left: 10px;
  padding-right: 10px;
}

.rightSideCurrentQuestionDetails {
  display: flex;
  align-items: center;
  gap: 5px;
}
.rightAnswerMarksOfCurrentQuestion {
  background-color: var(--dark-green-bg-color);
  color: var(--white-color);
  /* padding: 2px 10px; */
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 40px;
  max-width: 50px;
  text-align: center;
  border-radius: var(--border-radius);
}
.wrongAnswerMarksOfCurrentQuestion {
  background-color: var(--pure-red-bg-color);
  color: var(--white-color);
  /* padding: 2px 10px; */
  padding: 2px;
  /* padding-top: 2px;
  padding-bottom: 2px; */
  min-width: 40px;
  max-width: 50px;
  text-align: center;
  border-radius: var(--border-radius);
}
.timeUsedByCurrentQuestion {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 3px;
  border: var(--dark-color) 1px solid;
  border-radius: var(--border-radius);
  /* padding-left: 3px;
  padding-right: 3px; */
  padding: 1px 5px;
}

/* current question main div design. main question part. */
.currentQuestionMainDiv {
  width: 100%;
  height: calc(100% - 130px);
  overflow-y: auto;
  padding: 10px;
}
.currentQuestionMainDivForMobile {
  width: 100%;
  height: calc(100% - 165px);
  /* height: calc(100% - 130px); */
  overflow-y: auto;
  padding: 10px;
}

/* bottom buttons design. */
.buttonsForCbtForMobile {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  justify-content: space-between;
  height: 90px;
  width: 100%;
  padding: 0 10px;
  border-top: var(--dark-color) 2px solid;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  /* background-color: var(--light-silver-shade-color); */
}
.buttonsForCbt {
  display: flex;
  /* flex-wrap: wrap; */
  /* gap: 10px; */
  justify-content: space-between;
  height: 55px;
  width: 100%;
  padding: 0 10px;
  border-top: var(--dark-color) 2px solid;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  /* background-color: var(--light-silver-shade-color); */
}
.buttonsForCbt .leftSideCbtButtons,
.buttonsForCbt .rightSideCbtButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonsForCbt .leftSideCbtButtons {
  gap: 10px;
}

.buttonsForCbtForMobile .rightSideCbtButtons {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.buttonsForCbtForMobile .leftSideCbtButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
