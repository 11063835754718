:root {
  --dark-color: #112d4e;
  --active-color: #92ff9d;
  --white-color: #ffffff;
  --decorative-color: #ffd642;
  --border-extreme-light-color: #ffffff44;
  --border-medium-dark-color: #0000005d;
  --border-radius: 3px;
  --light-silver-shade-color: #eaeded;
}

.rightSideQuestionNumberDetailsComponentMainDiv {
  border-radius: var(--border-radius);
  border: var(--border-medium-dark-color) 1px solid;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.topSubjectName {
  height: 35px;
  background-color: var(--light-silver-shade-color);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* number description design. */
.topIconDescription {
  height: 158px;
  display: flex;
  flex-direction: column;
  padding: 9px;
  border-bottom: var(--light-silver-shade-color) 2px solid;
}
.firstRow,
.secondRow,
.thirdRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.wrongQuestionIcon,
.rightQuestionIcon,
.markedForReviewQuestionIcon,
.notVisitedQuestionIcon,
.answeredAndMarkedForReviewQuestionIcon {
  display: flex;
  align-items: center;
}
.rightSideTopIconSpan {
  font-size: 12px;
}
/* .wrongQuestionIcon span,
.rightQuestionIcon span,
.markedForReviewQuestionIcon span,
.notVisitedQuestionIcon span,
.answeredAndMarkedForReviewQuestionIcon span {
  font-size: 14px;
} */
/* .wrongQuestionIcon span,
.rightQuestionIcon span,
.markedForReviewQuestionIcon span,
.notVisitedQuestionIcon span,
.answeredAndMarkedForReviewQuestionIcon span {
 
  font-size: 12px;
} */

/* answered question design. */
.answeredQuestionList {
  height: calc(100% - 248px);
  width: 100%;
  overflow-y: auto;
  /* overflow-y: scroll; */
}

/* number of questions list by answered type. */
.answeredQuestionListInnerDiv {
  margin: 10px;
  /* margin-top: 5px;
  margin-bottom: 5px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  /* column-gap: 5px;
  row-gap: 5px; */
}

/* submit button div design. */
.submitButtonMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  /* position: absolute;
  bottom: 10px; */
  /* width: 300px; */
  border-top: var(--dark-color) 2px solid;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  background-color: var(--light-silver-shade-color);
  /* background-color: blue; */
}
.submitButton {
  /* padding: 4px 15px; */
  padding-top: 4px;
  padding-bottom: 4px;
  width: 95%;
}
