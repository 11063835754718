/* .TestSeriesCard {
  margin-bottom: 25px;
  width: 275px;
  min-width: 275px;
  height: 340px;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 40px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1), -5px -5px 20px rgba(0, 0, 0, 0.1);
}
.TestSeriesCard:hover {
  scale: 1.05;
}

.TestSeriesCardImageDiv {
  margin: auto;
  width: 175px;
  height: 100px;
  position: relative;
  top: -20px;
}
.TestSeriesCardImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1), -5px -5px 20px rgba(0, 0, 0, 0.1);
}
.TestSeriesCardName {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
}
.TestSeriesCardDescription {
  font-size: 15px;
  width: 80%;
  margin: auto;
  text-align: justify;
  height: 80px;
  padding: 10px 0;
  text-transform: capitalize;
  font-weight: 500;
}
.TestSeriesCardNumberOfTestsDiv {
  font-size: 15px;
  height: 20px;
  width: 80%;
  margin: 5px auto;
}
.TestSeriesCardNumberOfTestsSpan {
  font-weight: 600;
}

.TestSeriesCardPublishedBy {
  margin: 5px auto;
  font-size: 15px;
  font-weight: 600;
  width: 80%;
  text-transform: capitalize;
}

.TestSeriesCardViewMoreButton {
  margin: 5px 10%;
  width: 80%;
  border-radius: 5px;
  border: none;
  background-color: #80ff06;
  cursor: pointer;
}
.TestSeriesCardViewMoreButton p {
  margin: 5px auto;
  font-size: 15px;
  font-weight: 500;
}
.TestSeriesCardViewMoreButton:hover {
  scale: 1.1;
}
.TestSeriesCardPrice {
  margin: 5px auto;
  font-size: 15px;
  font-weight: 600;
  width: 80%;
  text-transform: capitalize;
}
.TestSeriesCardPriceDeleteMsp {
  font-size: 12px;
  font-weight: 500;
} */
.TestSeriesCard {
  margin-bottom: 25px;
  width: 275px;
  min-width: 275px;
  /* width: 275px;
  min-width: 275px; */
  height: 355px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1), -5px -5px 20px rgba(0, 0, 0, 0.1);
  /* box-shadow: 10px 5px 5px red; */
}
.TestSeriesCard:hover {
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.05),
    inset -5px -5px 10px rgba(0, 0, 0, 0.05);
  /* scale: 1.05; */
}

.TestSeriesCardImageDiv {
  margin: auto;
  width: 225px;
  height: 140px;
  /* width: 175px;
  height: 100px; */
  position: relative;
  top: -40px;
  margin-bottom: -30px;
}
.TestSeriesCardImage {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1), -5px -5px 20px rgba(0, 0, 0, 0.1);
}
.TestSeriesCardName {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  /* text-transform: capitalize; */
}
.TestSeriesCardDescription {
  font-size: 15px;
  width: 80%;
  margin: auto;
  text-align: justify;
  height: 80px;
  padding: 10px 0;
  text-transform: capitalize;
  font-weight: 500;
  overflow: hidden;
}
.TestSeriesCardNumberOfTestsDiv {
  font-size: 15px;
  height: 20px;
  width: 80%;
  margin: 5px auto;
}
.TestSeriesCardNumberOfTestsSpan {
  font-weight: 600;
  /* font-size: 20px; */
}

.TestSeriesCardPublishedBy {
  margin: 5px auto;
  font-size: 15px;
  font-weight: 600;
  width: 80%;
  text-transform: capitalize;
}

.TestSeriesCardViewMoreButton {
  margin: 5px 10%;
  width: 80%;
  border-radius: 5px;
  border: none;
  background-color: #80ff06;
  cursor: pointer;
}
.TestSeriesCardViewMoreButton p {
  margin: 5px auto;
  font-size: 15px;
  font-weight: 500;
}
.TestSeriesCardViewMoreButton:hover {
  scale: 1.1;
}
.TestSeriesCardPrice {
  margin: 5px auto;
  font-size: 15px;
  font-weight: 600;
  width: 80%;
  text-transform: capitalize;
}
.TestSeriesCardPriceDeleteMsp {
  font-size: 12px;
  font-weight: 500;
}
