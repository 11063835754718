:root {
  --marked-color: #8800d6;
  /* --dark-color: #112d4e; */
  --white-color: #ffffff;
  --green-bg-color: #04ff00;
  --dark-green-bg-color: #04da00;
  --red-bg-color: #ff0000;
  --standard-full-box-width: 46px;
  --standard-full-box-height: 46px;
}

/* right and wrong questions design. */
.rightNumberBoxMainDiv,
.wrongNumberBoxMainDiv {
  /* padding-top: 8px; */
  padding: 6px 4px;
  /* padding-top: 4px;
  padding-bottom: 4px; */
  width: var(--standard-full-box-width);
  height: var(--standard-full-box-height);
  /* width: 40px;
  height: 40px; */
}
.rightNumberBox {
  width: 100%;
  height: 100%;
  background-color: var(--green-bg-color);
  clip-path: polygon(25% 0%, 75% 0%, 100% 27%, 100% 100%, 0 100%, 0 27%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-color);
}
.wrongNumberBox {
  width: 100%;
  height: 100%;
  background-color: var(--red-bg-color);
  clip-path: polygon(0 0, 100% 0, 100% 77%, 75% 100%, 25% 100%, 0 77%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  /* color: var(--dark-color); */
}

/* mark for review design. */
.answeredAndMarkedForReviewBoxMainDiv,
.markedForReviewBoxMainDiv {
  width: var(--standard-full-box-width);
  height: var(--standard-full-box-height);
  /* width: 40px;
  height: 40px; */
  padding: 3px;
}
.answeredAndMarkedForReviewBox,
.markedForReviewBox {
  width: 100%;
  height: 100%;
  clip-path: circle(50% at 50% 50%);
  background-color: var(--marked-color);
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.answeredAndMarkedForReviewBoxMainDiv::after {
  content: "";
  width: 10px;
  height: 10px;
  clip-path: circle(50% at 50% 50%);
  background-color: var(--dark-green-bg-color);
  display: block;
  position: relative;
  bottom: 12px;
  left: 29px;
}

/* not visited design. */
.notVisitedBoxMainDiv {
  width: var(--standard-full-box-width);
  height: var(--standard-full-box-height);
  /* width: 40px;
  height: 40px; */
  padding: 5px;
}
.notVisitedBox {
  width: 100%;
  height: 100%;
  border: var(--dark-color) 2px solid;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
