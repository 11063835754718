/* copied design */
.testSeriesPage_firstSection {
  border: 1px solid rgba(1, 1, 1, 0.5);
  border-radius: 5px;
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-wrap: wrap;
}
.testSeriesPage_firstSection .testSeriesPage_img {
  max-width: 300px;
  width: 90%;
  border-radius: 5px;
  /* height: 200px; */
  height: auto;
  margin: 10px;
  box-shadow: 5px 5px 10px rgba(1, 1, 1, 0.25),
    -5px -5px 10px rgba(1, 1, 1, 0.25);
}
.testSeriesPage_firstSection .testSeriesPage_1s_1d {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 5px;
}
.testSeriesPage_firstSection .testSeriesPage_1s_1d p {
  font-size: 18px;
  font-weight: 600;
}

/* older design */
.singleTestSeriesSlider {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: capitalize;
  overflow: auto;
  max-width: 100%;
  border-bottom: 1px solid black;
  position: sticky;
  top: 60px;
  background-color: white;
}
.singleTestSeriesSingleSlider {
  flex: 1;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  /* z-index: 5; */
}
.activeSingleTestSeriesSingleSlider {
  background-color: rgba(8, 255, 8, 0.75);
}
.singleTestSeriesSingleSlider span {
  width: max-content;
  display: block;
}

/* details page design. */
.singleTestSeriesTopImageDiv {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(192, 192, 192, 0.25);
}
.singleTestSeriesTopImage {
  height: 100%;
  max-width: 100%;
  width: auto;
  /* object-fit: cover; */
}

.singleTestSeriesTitle {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: capitalize;
}

.singleTestSeriesColumnFlex {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10px auto;
  text-transform: capitalize;
}
.singleTestSeriesRowFlexName {
  font-size: 20px;
  font-weight: 600;
}
.singleTestSeriesColumnFlexName {
  font-size: 20px;
  font-weight: 600;
  text-decoration: underline;
  /* border-bottom: 1px solid black; */
}
.singleTestSeriesAbout {
  font-size: 17px;
  font-weight: 500;
  width: 90%;
  margin: 10px 0 10px auto;
  text-align: justify;
  text-transform: capitalize;
}
.singleTestSeriesDescription {
  font-size: 16px;
  width: 90%;
  margin: 10px 0 10px auto;
  text-align: justify;
  text-transform: capitalize;
}
.singleTestSeriesPriceDeleteMsp {
  font-size: 15px;
  font-weight: 400;
}
.singleTestSeriesurchaseNowDiv {
  /* width: 80%; */
  width: 250px;
  max-width: 80%;
  height: 40px;
  margin: 0 auto 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(8, 255, 8, 0.75);
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 10px;
}
.singleTestSeriesurchaseNowDiv:hover {
  scale: 1.05;
}

/* All Tests Design */
.singleTestSeriesAllTestsMainDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.sectionNameInSingleTestSeriesAllTests {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  height: 40px;
  padding: 5px 20px;
  /* text-align: left; */
  background-color: rgba(192, 192, 192, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropDownIcon-SingleTestSeriesAllTests {
  scale: 0.8;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
}
.dropDownIconActive-SingleTestSeriesAllTests {
  scale: 0.8;
  transform: rotate(-90deg);
  transition: all 0.5s ease-in-out;
  z-index: -1;
}

.allTestsUnderSingleSectionMainDiv-singleTestSeriesAllTests {
  max-width: 90%;
  width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  gap: 10px;
}

.singleTestMainDiv-singleTestSeriesAllTests {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  /* height: 90px; */
  border: black 1px solid;
  border-radius: 5px;
}
.singleTestTopDiv-singleTestSeriesAllTests {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  height: 90px;
  /* height: 60px; */
  border-bottom: silver 1px solid;
}
.testName-singleTestSeriesAllTests {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.rightSideOfTestName-singleTestSeriesAllTests {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-evenly;
  gap: 2px;
}
.free-giveTestButton-singleTestSeriesAllTests {
  width: max-content;
  background-color: #0dfa28;
  padding: 5px 10px;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  /* color: white; */
}
.giveTestButton-singleTestSeriesAllTests {
  width: max-content;
  background-color: #f91303;
  padding: 5px 10px;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  color: white;
}

.singleTestSecondDiv-singleTestSeriesAllTests {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  padding: 0 10px;
  height: 30px;
  font-size: 12px;
}
