.allTestSeriesTopTitle {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin: 10px auto 30px;
  /* padding: 5px; */
  /* border-bottom: black 1px solid; */
}
.allTestSeriesTopTitle span {
  padding: 5px 30px;
  border-bottom: black 1px solid;
}

/* all test series cards design. */
.AllTestSeriesCardsInAllTestSeriesDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 20px; */
  gap: 0px 20px;
  /* z-index: -1; */
}
