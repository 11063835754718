:root {
  --dark-color: #112d4e;
  --active-color: #92ff9d;
  --white-color: #ffffff;
  --decorative-color: #ffd642;
  --border-extreme-light-color: #ffffff44;
  --border-radius: 5px;
}

.fullCbtPageMainDiv {
  background-color: var(--white-color);
  height: 100vh;
  width: 100vw;
}

.topTitleMainDiv {
  width: 100%;
  padding: 10px;
}
.topTitleDiv {
  background-color: var(--dark-color);
  color: var(--decorative-color);
  border-radius: var(--border-radius);
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topTitleSpan {
  font-size: 25px;
}
.topTitleSpanForMobile {
  font-size: 18px;
}
.cbtExamTopTitleRightSide {
  display: flex;
  align-items: center;
  gap: 15px;
}
.toggleRightSideQuestionNumbers {
  width: 30px;
  height: 30px;
}

/* below exam component design. */
.examQuestionsMainDiv {
  /* background-color: red; */
  padding: 0 10px 10px;
  height: calc(100% - 80px);
  width: 100%;
}

.fullWidthWithQuestionNumbersMainDiv {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
}
.leftSideQuestionsDetailsMainDiv {
  width: calc(100% - 310px);
  height: 100%;
  /* background-color: green; */
}
.leftSideQuestionsDetailsMainDivForMobile {
  /* width: 100vw; */
  width: 100%;
  height: 100%;
  /* background-color: green; */
}
.rightSideQuestionNumberDetailsMainDiv {
  width: 300px;
  height: 100%;
  /* background-color: blue; */
}
.smallMobileRightQuestionNumbersToggled {
  /* width: 100vw; */
  width: 320px;
  height: calc(100vh - 80px);
  padding: 0 10px 10px;
  position: absolute;
  top: 80px;
  right: 0;
  /* background-color: white; */
  display: flex;
  justify-content: right;
}
.rightSideQuestionNumberDetailsMainDivForSmallMobile {
  width: 300px;
  /* width: calc(100% - 20px); */
  /* padding: 0 10px; */
  height: 100%;
}
