:root {
  --cbt-bottom-buttons-bg-color: #0050f0;
  --cbt-bottom-buttons-font-color: #ffffff;
}

.simpleWhitePushableButton {
  width: 9em;
  height: 3em;
  border-radius: 5px;
  /* border-radius: 30em; */
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* box-shadow: 6px 6px 12px #c5c5c586, -6px -6px 12px #ffffff88; */
  cursor: pointer;
  font-weight: 600;
}

.simpleWhitePushableButton::before {
  content: "";
  width: 0;
  height: 3em;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: linear-gradient(to right, #00ff51 0%, #00ff51 100%); */
  background-image: linear-gradient(to right, #00ff51 0%, #00ff51 100%);
  transition: 0.4s ease;
  display: block;
  z-index: -1;
}

.simpleWhitePushableButton:hover::before {
  width: 100%;
  /* width: 9em; */
}
.simpleWhitePushableButton:active {
  font-weight: 800;
  transform: scale(1.05);
}

/* cbt bottom buttons design. */
.CbtLeftBottomButtonComponent {
  padding: 5px 15px;
  height: 30px;
  background-color: var(--cbt-bottom-buttons-bg-color);
  color: var(--cbt-bottom-buttons-font-color);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.CbtLeftBottomButtonComponent:active {
  scale: 1.05;
}
.CbtLeftBottomButtonComponent span {
  text-transform: capitalize;
}
