/* constants */
:root {
  --dark-color: #112d4e;
  --active-color: #92ff9d;
  --white-color: #ffffff;
  --decorative-color: #ffd642;
  --border-extreme-light-color: #ffffff44;
  --dropdown-animation-time: 0.25s;
}

/* header design */
.headerMaindiv {
  height: 60px;
  width: 100vw;
  background-color: var(--dark-color);
  color: var(--white-color);
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.topLogo {
  color: var(--decorative-color);
  font-size: 30px;
  position: relative;
  top: 10px;
  cursor: pointer;
}
.navBarMainDiv {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.navBarMainDiv .singleNavElementMainDiv,
.singleSignInElementMainDiv {
  cursor: pointer;

  padding-top: 15px;
  /* width: 100px; */
  text-align: center;
  /* max-width: 100px; */
}
.navBarMainDiv .singleNavElementMainDiv .navSingleElementNameDiv {
  position: relative;
  right: 0;
  top: 20;
}
.navBarMainDiv .singleNavElementMainDiv .navSingleElementNameDiv span,
.singleSignInElementMainDiv {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 17px;
  height: 30px;
}
.navBarMainDiv .singleNavElementMainDiv .navSingleElementNameDiv:hover {
  color: var(--active-color);
  scale: 1.1;
}
.navSingleElementNameDivActive {
  color: var(--active-color);
  scale: 1.1;
}
.singleSignInElementMainDiv {
  margin-top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDown {
  min-width: 200px;
  /* min-width: 150px; */
  position: relative;
  right: 100px;
  /* right: 55px; */
  background-color: var(--dark-color);
  text-align: right;
  border: var(--white-color) 2px solid;
  border-radius: 5px;
  padding: 10px 5px 5px 5px;
}

.navSingleElementIconActive {
  transform: rotate(-90deg);
  transition: all var(--dropdown-animation-time) linear;
}
.navSingleElementIcon {
  transform: rotate(0deg);
  transition: all var(--dropdown-animation-time) linear;
}

.singleDwopDownName {
  padding: 5px 3px;
  border-bottom: var(--border-extreme-light-color) 1px solid;
}
.singleDwopDownName:hover {
  background-color: var(--white-color);
  color: var(--dark-color);
  scale: 0.95;
  padding: 5px 5px;
  border-radius: 5px;
}

.navSingleElementName {
  color: var(--white-color);
  scale: 1.1;
  transition: all var(--dropdown-animation-time) linear;
}
.navSingleElementNameActive {
  color: var(--active-color);
  scale: 1.2;
  transition: all var(--dropdown-animation-time) linear;
}
