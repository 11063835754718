.signup_main_full_div {
  width: 100vw;
  background-color: #edf1f4;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_form_main_div {
  width: max-content;
  height: max-content;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.1), -15px -15px 20px #fffb;
  border-radius: 20px;
  gap: 20px;
  padding: 50px 30px 50px;
  /* padding: 50px auto; */
}
.signup_form_single_input {
  position: relative;
}
.signup_form_single_input input[type="text"],
.signup_form_single_input input[type="email"],
.signup_form_single_input input[type="number"],
.signup_form_single_input input[type="password"] {
  width: 250px;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1), inset -5px -5px 10px #fff;
  border-radius: 10px;
  padding-left: 30px;
}
.signup_form_single_input .login_fields_icon {
  position: absolute;
  left: 10px;
  top: 30%;
}
.verify_email_button_div {
  width: 250px;
  position: relative;
}
.verify_email_button {
  margin-top: 35px;
  width: 100%;
  height: 40px;
  background: rgba(0, 0, 255, 0.2);
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25), -5px -5px 10px #fff;
}
.verify_email_button:active {
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.1),
    inset -5px -5px 10px #aeb1e6;
}
