:root {
  --omr-background-decorative-color: #ffeaf3;
  --light-black-border-color: rgba(0, 0, 0, 0.4);
}

.createOmrFullDiv {
  width: 100vw;
  height: calc(100vh - 100px);
  /* height: calc(100vh - 50px); */
  position: absolute;
  top: 0;
  top: 50px;
  /* left: 0; */
  /* background-color: #a8a8a8; */
  background-image: url("/public/images/woodenBench.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  /* padding: 10px 0 0; */
  /* z-index: -1; */
  /* padding: 10px 0 50px; */
  /* z-index: 5; */
}
.omrFullSizeOuterDiv {
  max-width: 100%;
  width: 600px;
  max-height: calc(100vh - 120px);
  overflow: auto;
}
.omrFullSizeDiv {
  background-image: linear-gradient(#ffffff, #ffffff);
  width: 585px;
  height: 842px;
}

/* omr main design is started. */
.omrTopRowWithCircles {
  display: flex;
  justify-content: space-between;
  /* align-items: end; */
  height: 81px;
  padding: 21px 21px 0 21px;
}
.omrTopRightCornerDiv {
  display: flex;
  align-items: end;
}
.circleForOrientationDetection {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
  margin-bottom: 10px;
  margin-right: 20px;
}
.omrBottomRowWithCircles {
  display: flex;
  justify-content: space-between;
  /* align-items: top; */
  height: 81px;
  padding: 0 21px 21px 21px;
}

/* corner circle design. */
.cornerCircle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cornerCircleInnerFirstCircle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cornerCircleInnerSecondCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
}

/* omr center part design */
.omrDetailsFullDiv {
  height: 680px;
  width: 423px;
  margin: auto;
  /* border: 2px black solid; */
}

.omrDetailsTopRow {
  height: 145px;
  /* height: 137px; */
  width: 100%;
  border: 2px black solid;
  display: flex;
}
.omrDetailsTopRowFirstElementMainDiv {
  width: 120px;
  height: 100%;
}
.omrDetailsTopRowSecondElementMainDiv {
  width: 70px;
  height: 100%;
  border-left: 2px black solid;
  border-right: 2px black solid;
  /* border: 2px black solid; */
}
.omrDetailsTopRowFirstElementInnerFirstDiv {
  width: 100%;
  height: 30px;
  border-bottom: 2px black solid;
}
.omrDetailsTopRowFirstElementTopSpan {
  font-size: 10px;
  text-align: center;
}
.enterRollNumberDiv {
  margin: auto;
  width: 120px;
  height: 13px;
  display: flex;
  justify-content: space-around;
}
.enterRollNumberSingleDigitDiv {
  width: 9px;
  height: 13px;
  border: 1px var(--light-black-border-color) solid;
  /* border: 1px rgba(0, 0, 0, 0.4) solid; */
}

/* fill omr options div */
.omrDetailsTopRowFirstElementCheckCirclesMainDiv {
  display: flex;
  width: 100%;
  height: 111px;
  /* height: 103px; */
  /* justify-content: space-around; */
}
.omrDetailsTopRowFirstElementCheckCirclesSingleColumnDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  background-color: red;
  width: calc(100% / 10);
}
.omrCheckForRollNumberOddColumn {
  background-color: white;
}
.omrCheckForRollNumberEvenColumn {
  background-color: var(--omr-background-decorative-color);
}
.rollNumberCheckCircle {
  width: var(--roll-number-circle-width);
  height: calc(var(--roll-number-circle-width) * 0.8);
  /* width: 9px;
  height: calc(9px * 0.8); */
  /* height: 9px; */
  border-radius: 50%;
  font-size: 5.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: rgba(0, 0, 0, 0.6) 1px solid;
  background-color: white;
}
.rollNumberCheckCircle span {
  position: relative;
  top: 0.25px;
}

/* top row second element. */
.omrDetailsTopRowSecondElementInnerFirstDiv {
  height: 30px;
  width: 100%;
}
.omrDetailsTopRowSecondElementTopSpan {
  font-size: 10px;
  text-align: center;
}
.omrDetailsTopRowSecondElementInnerSecondDiv {
  height: 108px;
  width: 60px;
  margin: auto;
  border: 1.5px solid black;
  padding-top: 5px;
}
.omrTestBookletSeriesInputTopBox {
  width: 50px;
  height: 30px;
  margin: auto;
  border: 1px var(--light-black-border-color) solid;
  /* border: 1px rgba(0, 0, 0, 0.4) solid; */
}

.omrTestBookletSeriesInputCircleMainDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.testSeriesCheckCircleParentDiv {
  /* width: 28.72px; */
  width: 27px;
  /* width: 25px; */
  height: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testSeriesCheckCircleParentDivEvenRow {
  background-color: var(--omr-background-decorative-color);
}
.testSeriesCheckCircleParentDivOddRow {
  background-color: white;
}
.testSeriesCheckCircle {
  width: var(--test-series-circle-width);
  height: calc(var(--test-series-circle-width) * 0.8);
  /* width: 11px;
  height: calc(11px * 0.8); */
  /* height: 9px; */
  border-radius: 50%;
  font-size: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: rgba(0, 0, 0, 0.6) 1px solid;
  background-color: white;
}
.testSeriesCheckCircle span {
  position: relative;
  top: 0.5px;
}

/* options container. */
.optionContainerMainDiv {
  margin-top: 6px;
  width: 423px;
  display: flex;
  justify-content: space-between;
  /* z-index: 1000; */
}
.optionContainerSingleColumnMainDiv {
  /* height: 490px; */
  width: min-content;
  border: black 2px solid;
}
.optionContainerColumnTopRow {
  display: flex;
  width: var(--option-with-number-width);
  height: var(--top-column-height);
  /* height: 10px; */
  /* border-right: #000 2px solid; */
  border-bottom: #000 2px solid;
}
.singleOptionRowNumberInSingleColumnTopRow {
  font-size: 5px;
  width: var(--num-width);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: #000 2px solid;
}
.optionsListInSingleColumnTopRow {
  display: flex;
  width: var(--options-width);
  justify-content: space-around;
  align-items: center;
}
.singleOptionInSingleColumnTopRow {
  width: 7px;
  height: 7px;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: rgba(0, 0, 0, 0.6) 0.5px solid; */
}
.singleOptionInSingleColumnTopRow span {
  font-size: 6px;
  font-weight: 700;
  position: relative;
  top: 0.5px;
}

.singleOptionRow {
  display: flex;
  width: var(--option-with-number-width);
  height: var(--options-row-height);
  /* height: 9.5px; */
  /* border-right: #000 2px solid; */
}
.singleOptionRowNumber {
  font-size: var(--options-row-number-font-size);
  /* font-size: 5px; */
  width: var(--num-width);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: #000 2px solid;
}
.optionSingleRowOptions {
  display: flex;
  width: var(--options-width);
  /* width: calc(65px - 12px); */
  justify-content: space-around;
  align-items: center;
}
.optionSingleRowOptionsBackGround {
  background-color: var(--omr-background-decorative-color);
}
.singleOption {
  width: var(--options-circle-width);
  height: calc(var(--options-circle-width) * 0.8);
  /* width: 7px;
  height: 7px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: rgba(0, 0, 0, 0.6) 0.5px solid;
  /* background-color: white; */
  cursor: pointer;
  z-index: 1;
  /* position: relative;
  top: 1px; */
}
.isSelected {
  background-color: #000;
}
.isNotSelected {
  background-color: white;
}
.singleOption span {
  text-transform: capitalize;
  font-size: var(--options-row-options-font-size);
  /* font-size: 4.5px; */
  position: relative;
  top: 0.3px;
}

/* background image. */
.backgroundImageDiv {
  position: relative;
  top: -450px;
  left: 120px;
  width: 350px;
}

.backgroundImage {
  width: 350px;
  margin: auto;
  opacity: 0.2;
  transform: rotate(-15deg);
}
