.cbtSingleQuestionMainDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cbtQuestion {
  display: flex;
  font-size: 22px;
  font-weight: 600;
  gap: 10px;
}
.cbtQuestionSpan {
  text-align: justify;
}

/* option design. */
.cbtQuestionOptions {
  padding: 5px;
  /* display: flex;
  flex-direction: column;
  gap: 5px; */
}
.cbtQuestionSingleOption {
  display: flex;
  /* justify-content: ; */
  align-items: center;
  cursor: pointer;
  width: max-content;
  margin: 5px 0;
}
.cbtQuestionSingleOption input,
.cbtQuestionSingleOption label {
  cursor: pointer;
}
.cbtQuestionSingleOptionLabel {
  padding-left: 10px;
}
.cbtQuestionSingleOptionLabel {
  font-size: 20px;
}
