.omrSingleQuestionMainDiv {
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding: 5px 0 5px;
}
.omrQuestion {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  gap: 10px;
}
.omrQuestionSpan {
  text-align: justify;
  display: flex;
  gap: 10px;
}

/* option design. */
.omrQuestionOptions {
  padding: 0 10px;
  /* display: flex;
  flex-direction: column;
  gap: 5px; */
}
.omrQuestionSingleOption {
  display: flex;
  /* justify-content: ; */
  align-items: center;
  cursor: pointer;
  width: max-content;
  margin: 3px 0;
}
.omrQuestionSingleOption input,
.omrQuestionSingleOption label {
  cursor: pointer;
}
.omrQuestionSingleOptionLabel {
  padding-left: 10px;
}
.omrQuestionSingleOptionLabel {
  font-size: 15px;
}
