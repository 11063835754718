@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Noto+Serif+Gujarati:wght@100..900&family=Roboto+Slab:wght@100..900&family=Tiro+Devanagari+Marathi:ital@0;1&display=swap");

:root {
  --dark-color: #112d4e;
  --active-color: #92ff9d;
  --white-color: #ffffff;
  --decorative-color: #ffd642;
  --border-extreme-light-color: #ffffff44;
  --border-medium-dark-color: #0000005d;
  --border-radius: 3px;
  --light-silver-shade-color: #eaeded;
}

.enterTheOmrTestMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.enterTheOmrTestButton {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.enterTheOmrTestButton:hover {
  background-color: #3e8e41;
}

/* in omr page. */
.fullOmrPageMainDiv {
  background-color: #ffffff;
  background-image: url("/public/images/woodenBench.jpg");
  background-size: cover;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; */
  height: 100vh;
  width: 100%;
  /* padding: 30px 0; */
  /* overflow: auto; */
  font-family: "Lora", "Tiro Devanagari Marathi", "Noto Serif Gujarati", serif;
  /* font-family: "Lora", serif;
  font-family: "Tiro Devanagari Marathi", serif;
  font-family: "Noto Serif Gujarati", serif; */
}
.paperJsxParentMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: auto;
  /* overflow: overlay; */
  height: calc(100% - 120px);
  width: 100%;
  /* padding: 15px 0; */
  margin: 10px 0;
  /* width: calc(100%-50px); */
}

.whiteBackGround {
  background-color: #ffffff;
}

.fullyTransperantForPreRendering {
  opacity: 0;
}

.paperInnerMainDiv {
  width: var(--paper-inner-width);
  height: var(--paper-inner-height);
}

.paperMainDiv {
  padding: 20px;
  /* padding: 10px 20px; */
  width: var(--paper-width);
  height: var(--paper-height);
  background-color: #ffffff; /* Light gray background color */
  /* border-radius: 5px; */
  border: rgba(17, 17, 17, 0.1) solid 1px;
  /* background-image: url("/public/plainPaper3.jpg"); */
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  background-image: linear-gradient(#cac9c938, #cfcece18, #b6b6b625);

  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.075),
    -5px -5px 20px rgba(0, 0, 0, 0.075);
  text-align: justify;
}

/* top bar design. */
.omrExamTopBar {
  height: 50px;
  background-color: var(--dark-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--decorative-color);
  /* z-index: 10; */
}
.omrExamTopBarExamName {
  font-size: 18px;
}
.showOmrIcon {
  font-size: 25px;
  padding-bottom: 4px;
  cursor: pointer;
}

.omrExamBottomBar {
  height: 50px;
  background-color: var(--dark-color);
  /* background-color: var(--light-silver-shade-color); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--decorative-color);
}
