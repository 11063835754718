.homePageMainDiv {
  background-color: white;
  /* z-index: 1000; */
}

/* testseries section design */
.homePageTestSeriesSectionTitleDiv {
  display: flex;
  justify-content: space-between;
  /* align-items: end; */
}
.homePageTestSeriesSectionTitle {
  font-size: 25px;
  /* text-decoration: underline; */
  border-bottom: 1px solid black;
  font-weight: bold;
  margin: 10px 20px;
  /* text-align: center; */
}
.homePageTestSeriesSectionViewAllTestSeries {
  text-decoration: underline;
  display: flex;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;
}

.homePageAllTestSeriesDiv {
  padding: 20px;
  /* padding: 0 20px; */
  display: flex;
  gap: 30px;
  overflow-x: auto;
  width: 100%;
  justify-content: space-evenly;
}
