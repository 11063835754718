:root {
  --dark-color: #112d4e;
  --active-color: #92ff9d;
  --white-color: #ffffff;
  --decorative-color: #ffd642;
  --border-extreme-light-color: #ffffff44;
  --dropdown-animation-time: 0.25s;
}

.mobileHeaderMainDiv {
  height: 60px;
  width: 100vw;
  position: sticky;
  /* left: 0; */
  top: 0;
  background-color: var(--dark-color);
  color: var(--white-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 10px;
  z-index: 10;
  /* z-index: 1000; */
}
.mobileNameHeader {
  color: var(--decorative-color);
  font-size: 25px;
  margin-left: 10px;
  cursor: pointer;
}
.mobileShowMenuIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 5px;
}
.mobileHideMenuIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 5px;
}
.mobileNavBarMainDiv {
  border-top: var(--white-color) 2px solid;
  background-color: var(--dark-color);
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 60px;
  z-index: 10;
  /* z-index: 1000; */
  /* text-align: left; */
}
.mobileNavBarBoxDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.mobileSingleNavFullDiv {
  display: flex;
  flex-direction: column;
  /* justify-content: left; */
  /* align-items: ; */
  cursor: pointer;
}
.mobileSinglesignInOutMainDiv {
  cursor: pointer;
}
.mobileSingleNavMainDiv,
.mobileSinglesignInOutMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  min-height: 30px;
  border-bottom: var(--border-extreme-light-color) 1px solid;
  padding: 10px 10px;
}
.mobileSingleNavMainDivActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  min-height: 30px;
  border-bottom: var(--border-extreme-light-color) 1px solid;
  padding: 10px 10px;
  background-color: var(--white-color);
  color: var(--dark-color);
  /* padding-top: 10px; */
}
.mobileSingleNavOnlyNameAndIcon,
.mobileSinglesignInOutOnlyNameAndIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.mobileSingleNavNameIcon,
.signInOutIcon {
  width: 20px;
  height: 20px;
}
.mobileSingleNavNameSpan,
.signInOutName {
  font-size: 20px;
}
.mobileDropdownMainDiv {
  border-left: var(--border-extreme-light-color) 1px solid;
  padding-left: 10px;
  margin: 10px 0 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mobileSingleDropDown {
  display: flex;
  align-items: center;
  gap: 10px;
}
